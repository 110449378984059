<template>
  <v-card class="pt-0 mt-0" style="box-shadow: none !important;">
    <v-card-title class="pt-0 mt-0">
      <span class="title">Send a message to {{ members.length }} {{ recipientType }}</span>
      <v-spacer></v-spacer>
      <div class="mr-2" v-if="isEmail">
        <v-menu offset-y max-width="350px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="warning"
                class="ml-2"
                small
                :disabled="messageTemplates.length < 1"
                v-bind="attrs"
                v-on="on"
            >
              Message Templates
              <v-icon
                  right
                  dark
              >
                mail_outline
              </v-icon>
            </v-btn>
          </template>
          <v-list>

            <v-list-item
                v-for="(message, index) in messageTemplates"
                :key="index"
                link
                three-line
            >
              <v-list-item-content @click="uploadMessageTemplate(message)">
                <v-list-item-title v-html="message.name"></v-list-item-title>
                <v-list-item-subtitle v-html="message.description"></v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-spacer></v-spacer>
      <message-send-as
          @changeMessageType="(value) => isEmail = value"
          :value="isEmail"
          :disabled="disabledSwitchSend"
          class="mr-2"
          :messageLength="finalMessageLength"
      />
      <v-tooltip bottom max-width="300" v-if="kpsCopy.length > 0 && recipientType === 'KPs'">
        <template v-slot:activator="{ on, attrs }">
          <div
              v-bind="attrs"
              v-on="on"
          >
            <v-switch
                color="primary"
                class="d-inline-block mt-0"
                v-model="isKpsSend"
                label="Send to CC’d KP2+"
                @change="$emit('changeKpsSend', isKpsSend)"
                hide-details
            >
            </v-switch>
          </div>
        </template>
        <div>
          Turn this on if you'd like to CC the previously-selected KP2-5s on this message.
        </div>
      </v-tooltip>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-alert class="my-1" v-if="!isEmail" dense type="info" outlined :value="true">
        This will send a text to all recipients with valid cell phone # and an email if they do not.
      </v-alert>
      <v-alert class="my-1" v-if="error" dense type="error" outlined :value="true">{{ error }}</v-alert>
      <v-alert class="my-1" v-if="!isEmail && badCellOrEmails.length" dense type="error" outlined :value="true">
        Members with a red icon have an undeliverable phone/email and will not receive this message at all.
      </v-alert>
      <v-alert class="my-1" v-if="isEmail && badEmails.length" dense type="error" outlined :value="true">
        Members with a red icon have an undeliverable email and will not receive this message.
      </v-alert>
      <div v-if="!isEmail">
        <send-text
          ref="sendText"
          :text="text"
          :propMessage="message"
          :members="members"
          :isEmail="isEmail"
          :client="client"
          :unique="unique"
          :recipientType="recipientType"
          @badVariables="onBadVariables"
          @error="childError"
          @setMessage="value => this.message = value"
          @finalMessageLength="value => this.setFinalMessageLength(value)"
        ></send-text>
      </div>
      <div v-if="isEmail">
        <send-email
          ref="sendEmail"
          :email="email"
          :members="members"
          :propMessage="message"
          :client="client"
          :isEmail="isEmail"
          :attachedFiles="attachedFiles"
          :isUploadingFiles="isUploadingFiles"
          :unique="unique"
          :recipientType="recipientType"
          @badVariables="onBadVariables"
          @error="childError"
          @maxFileSize="onChangeMaxFileSize"
          @onAttachedFiles="onAttachedFiles"
          @updateFilesSize="onFileSizeUpdate"
          @setMessage="value => this.message = value"
          @finalMessageLength="value => this.setFinalMessageLength(value)"
        ></send-email>
      </div>
      <div v-if="!isSendNow" class="mt-2 px-2 full-width">
        <div class="caption grey--text pb-3">Schedule</div>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newdate"
                  label="Select date"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :min="currentDate"
                color="primary"
                no-title
                scrollable
                @change="$refs.dateMenu.save(date)"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-menu
              ref="timeMenu"
              v-model="timeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              :disabled="!date"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="getFormattedTime"
                  label="Select time"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :clearable="text.id == null"
                  :disabled="!date"
                  hide-details
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="timeMenu"
                v-model="time"
                :min="currentDate == date ? currentTime : ''"
                color="primary"
                full-width
                ampm-in-title
                @click:minute="$refs.timeMenu.save(time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <v-flex>
        <v-checkbox
          color="primary"
          v-model="isSendNow"
          label="Send Now"
          hide-details
          class="mt-3"
        >
        </v-checkbox>
      </v-flex>
      <v-alert
        v-if="isSummaryUploadLimit"
        class="mt-3"
        dense
        type="error"
        outlined
        :value="true"
      >
        The size of images and attached files is more than 25MB
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-layout wrap class="justify-space-between">
        <v-tooltip v-if="sendCopyCheckbox" top class="pb-4">
          <template v-slot:activator="{ on }">
            <v-checkbox
              color="primary"
              hide-details
              class="mt-3 admins-copies_checkbox"
              v-model="sendCopiesToAdmins"
              v-on="on"
            >
              <template v-slot:label>
                <p class="mb-0">Send copy to select Admins<span class="primary--text">({{ clientAdmins }})</span></p>
              </template>
            </v-checkbox>
          </template>
          <span>This will send a copy of this message to all Admins who have their<br> "Receive copy of outgoing messages" in their Profile within your Team page.</span>
        </v-tooltip>
        <div class="mt-3">
          <v-btn
            outlined
            color="secondary"
            class="mr-2"
            @click.native="closeModal"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="isSendNow"
            color="primary"
            :loading="isBusy"
            :disabled="disabledSendButton || isSummaryUploadLimit || isBadVariables"
            @click.native="onSend"
          >
            Send
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :loading="isBusy"
            :disabled="!time && !date && disabledSendButton || isBadVariables"
            @click.native="onSendSchedule"
          >
            Save & Schedule
          </v-btn>
        </div>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import sendEmail from '@/components/kp-tasks/send-message-modal/send-email'
import sendText from '@/components/kp-tasks/send-message-modal/send-text'
import MessageSendAs from "@/components/global/message-send-as"
import swal from "sweetalert2";
import he from "he";
import TinyConfig from "@/mixins/tiny-mce-config";
import ClientService from "@/services/ClientService";
import CustomMessageService from "@/services/CustomMessageService";

export default {
  name: 'SendMessageComponent',
  mixins: [TinyConfig, ClientService, CustomMessageService],
  components: {
    sendEmail,
    sendText,
    MessageSendAs,
  },
  props: {
    client: {
      type: Object,
      default: () => {
        return {}
      }
    },
    recipientType: {
      type: String,
      default: ''
    },
    members: {
      type: Array,
      default: () => {
        return []
      }
    },
    kpsCopy: {
      type: Array,
      default: () => {
        return []
      }
    },
    unique: {
      type: Boolean,
      default: false
    },
    filter: {
      type: [Object, Array],
      default: () => {},
    },
    fromPage: {
      type: String,
      default: 'Dashboard Page'
    },
  },
  data() {
    return {
      isKpsSend: false,
      isEmail: true,
      disabledSwitchSend: false,
      error: '',
      message: '',
      sendCopiesToAdmins: false,
      isUploadingFiles: false,
      isBadVariables: false,
      allMessageTemplates: [],
      messageTemplates: [],
      attachedFiles: [],
      isSendNow: true,
      dateMenu: false,
      timeMenu: false,
      isBusy: false,
      date: null,
      time: null,
      finalMessageLength: 0,
      text: {
        message: '',
        is_sms: true,
        formattedMessage: '',
        finalMessageLength: 0,
        member_legislator: [],
        show_legislators: 1
      },
      email: {
        from: '',
        reply_to_email: '',
        subject: '',
        message: '',
        is_sms: false,
        formattedMessage: '',
        finalMessageLength: 0,
        files: [],
        member_legislator: []
      },
    }
  },
  computed: {
    newdate: {
      get() {
        if (this.date == null || this.date == '') {
          return ''
        } else {
          return moment.utc(this.date).format('MMM D, YYYY')
        }
      },
      set(value) {
        if (value == null) {
          this.data = ''
        } else {
          this.data = moment.utc(value).format('MMM D, YYYY')
        }
      }
    },
    isShowingUnique() {
      return this.unique || this.recipientType === 'Coordinators'
    },
    titleModalHasEzLink() {
      if (this.hasEzLogin || this.recipientType !== 'KPs') {
        return `Ready to ${this.titleButtonSend}?`
      } else {
        return 'You did not include an ez_login link and KPs will not be able to easily log into the Dashboard. ' +
          `If this was on purpose, please click ${this.titleButtonSend}. If you'd like for KPs to easily self-report their progress, ` +
          'click Continue Editing and add the {ez_login} variable into the message body.'
      }
    },
    titleButtonSend() {
      return this.isSendNow ? 'Send' : 'Save & Schedule'
    },
    hasEzLogin() {
      let reg = /{ez_login}/
      return reg.test(this.message)
    },
    disabledSendButton() {
      const checkEmail = !this.message || this.maxSizeFile
      const checkText = !this.message || this.text.finalMessageLength > 320
      return (this.isEmail && checkEmail) || (!this.isEmail && checkText) || (this.members && !this.members.length)
    },
    sendCopyCheckbox() {
      return this.client
        && this.client.send_mailgun_notifications
        && this.clientAdmins
        && this.clientAdmins.length > 0;
    },
    getFormattedTime() {
      return this.time ? moment(this.time, "hh:mm").format('h:mm a') : ''
    },
    isSummaryUploadLimit() {
      return (this.updatedImgSize + this.updatedFilesSize) > this.imgSizeLimit
    },
    currentTime() {
      const timezone = this.getTimezone(this.client);
      return moment().tz(timezone).add(1, 'hour').format('H:m');
    },
    badCellOrEmails() {
      return this.members.filter(member => (!member.cell || member.bad_cell) || this.isBad(member.email))
    },
    badEmails() {
      return this.members.filter(member => this.isBad(member.email))
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    formatKpsCopy() {
      return [...this.kpsCopy.map(kp => {
        return {
          member_id: kp.member_id,
          legislator_id: kp.leg_id
        }
      })]
    },
  },
  methods: {
    init() {
      this.isBadVariables = false
      this.isEmail = true
      this.text = {
        message: '',
        is_sms: true,
        formattedMessage: '',
        finalMessageLength: 0,
        member_legislator: [],
        show_legislators: 1
      }
      this.email = {
        from: '',
        reply_to_email: '',
        subject: '',
        message: '',
        is_sms: false,
        formattedMessage: '',
        finalMessageLength: 0,
        files: [],
        member_legislator: []
      }
      this.isSendNow = true
      this.isPreview = false
      this.isKpsSend = false
      this.isBusy = false
      this.error = ''
      this.$nextTick().then(() => {
        if (this.$refs.sendEmail && this.$refs.sendEmail.$refs.emailForm) this.$refs.sendEmail.$refs.emailForm.reset()
      }).then(async () => {
        await this.getMessageTemplates()
        this.message = `Hi {advocate_first},<p><br/></p>Thanks! -${this.userData.nickname}`
        this.setText()
        this.setEmail()
      })
    },
    setText() {
      this.text = {
        clientId: this.client.id,
        is_sms: 1,
        is_kps_send: this.isKpsSend ? 1 : 0,
        finalMessageLength: 0
      }
    },
    setEmail() {
      this.email = {
        clientId: this.client.id,
        is_kps_send: this.isKpsSend ? 1 : 0,
        from: this.userName,
        is_sms: 0,
        show_legislators: +!this.isShowingUnique
      }
    },
    getMessageTemplates() {
      this.getCustomMessages(this.client.id)
          .then((response) => {
            this.allMessageTemplates = response.data
          })
          .catch((err) => {
            this.error = err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Something went wrong!'
          })
    },
    uploadMessageTemplate(message) {
      this.message = message.body
    },
    onChangeMaxFileSize(value) {
      this.maxSizeFile = value
    },
    dateTimeFormatter(value) {
      if (!value) return ''
      const utc = moment.utc(value).toDate()
      return moment(utc).local().format('MMM D, YYYY hh:mm A')
    },
    onAttachedFiles(value) {
      this.attachedFiles = value
    },
    html2Text(html) {
      let find = /(<br>|<\/p><p>)/,
        re = new RegExp(find, 'g');
      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    setFinalMessageLength(value) {
      this.finalMessageLength = value
    },
    async onSendEmail() {
      this.email.message = this.message
      this.email.trackFilter = {
        from: this.fromPage,
        filter: this.filter,
        recipientType: this.recipientType,
        unique: this.unique
      }
      await this.addFiles()
      await this.saveClientScheduleMessage(this.email).then(() => {
        this.onCallToastr('success', 'Email has been sent.', 'Success!')
        this.closeModal()
      }, (err) => {
        this.isBusy = false
        this.somethingWentWrong()
        console.error(err)
      })
    },
    async onSendText() {
      this.text.message = this.html2Text(this.message)
      this.text.trackFilter = {
        from: this.fromPage,
        filter: this.filter,
        recipientType: this.recipientType,
        unique: this.unique
      }
      await this.saveClientScheduleMessage({...this.text}).then(() => {
        this.onCallToastr('success', 'Text has been sent.', 'Success!')
        this.closeModal()
      }, (err) => {
        this.somethingWentWrong()
        console.error(err)
      })
    },
    onSend() {
      swal({
        text: this.titleModalHasEzLink,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.titleButtonSend,
        cancelButtonText: 'Continue Editing',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (!this.members || !this.members.length) {
            this.somethingWentWrong()
            return
          }

          if (this.isEmail) {
            if (!this.$refs.sendEmail.validationForm) return
            this.isBusy = true
            this.email.is_sms = 0
            this.email.is_admin_send = this.sendCopiesToAdmins ? 1 : 0
            this.email.show_legislators = +!this.isShowingUnique
            this.email.is_kps_send = this.isKpsSend ? 1 : 0
            this.email.kp2_members = this.formatKpsCopy

            // When kps selected 
            if (this.recipientType === 'KPs') {
              this.email.from_dashboard_page = true
              
              if (!this.unique) {
                //When select "1 Per Legislator"
                //With per filter or without filter 
                if(this.filter && this.filter.legislators && this.filter.legislators.length) {
                  this.email.member_legislator = this.filter.legislators.map(legislator => {
                    return {
                      member_id: legislator.member_legislators[0].member_id,
                      legislator_id: legislator.id,
                    }
                  })
                } else {
                  this.email.member_legislator = this.members.map(member => {
                    return {
                      member_id: member.id,
                      legislator_id: member.legislator.id,
                    }
                  })
                }

              } else {
                this.email.member_legislator = this.members.map(member => {
                  return {
                    member_id: member.id,
                    legislator_id: member.legislator.id,
                  }
                })
              }
            }
            if (this.recipientType === 'Coordinators') {
              this.email.to_regional_coordinators = true
            }
            if (this.recipientType === 'Advocates') {
              this.email.from_members_page = true
            }
            if(this.recipientType !== 'KPs') {
              this.email.members = this.members.filter(member => member.email).map(member => member.id)
            }
            delete this.email.delivery_at
            this.onSendEmail()
          } else {
            this.isBusy = true
            this.text.is_sms = 1
            this.text.show_legislators = +!this.isShowingUnique
            this.text.is_admin_send = this.sendCopiesToAdmins ? 1 : 0
            this.text.is_kps_send = this.isKpsSend ? 1 : 0
            this.text.kp2_members = this.formatKpsCopy

            if (this.recipientType === 'KPs') {
              this.text.from_dashboard_page = true

              if (!this.unique) {
                if(this.filter && this.filter.legislators && this.filter.legislators.length) {
                  this.text.member_legislator = this.filter.legislators.map(legislator => {
                    return {
                      member_id: legislator.member_legislators[0].member_id,
                      legislator_id: legislator.id,
                    }
                  })
                } else {
                  this.text.member_legislator = this.members.map(member => {
                    return {
                      member_id: member.id,
                      legislator_id: member.legislator.id,
                    }
                  })
                }

              } else {
                this.text.member_legislator = this.members.map(member => {
                  return {
                    member_id: member.id,
                    legislator_id: member.legislator.id,
                  }
                })
              }
            }
            if (this.recipientType === 'Coordinators') {
              this.text.to_regional_coordinators = true
            }
            if (this.recipientType === 'Advocates') {
              this.text.from_members_page = true
            }
            if(this.recipientType !== 'KPs') {
              this.text.members = this.members.filter(member => member.email).map(member => member.id)
            }
            delete this.text.delivery_at

            this.onSendText()
          }
        }
      })
    },
    onSendSchedule() {
      swal({
        text: this.titleModalHasEzLink,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.titleButtonSend,
        cancelButtonText: 'Continue Editing',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (!this.members || !this.members.length) {
            this.somethingWentWrong()
            return
          }

          if (this.isEmail) {
            if (!this.$refs.sendEmail.validationForm) return
            this.isBusy = true
            this.email.is_sms = 0
            this.email.is_admin_send = this.sendCopiesToAdmins ? 1 : 0
            this.email.delivery_at = `${this.date} ${this.time}`
            this.email.is_kps_send = this.isKpsSend ? 1 : 0
            this.email.kp2_members = this.formatKpsCopy

            if (this.recipientType === 'KPs') {
              this.email.from_dashboard_page = true
              this.email.member_legislator = this.members.map(member => {
                return {
                  member_id: member.id,
                  legislator_id: member.legislator.id,
                }
              })
            }
            if (this.recipientType === 'Coordinators') {
              this.email.to_regional_coordinators = true
            }
            if (this.recipientType === 'Advocates') {
              this.email.from_members_page = true
            }
            if(this.recipientType !== 'KPs') {
              this.email.members = this.members.filter(member => member.email).map(member => member.id)
            }

            this.onSendEmail()
          } else {
            this.isBusy = true
            this.text.is_sms = 1
            this.text.is_admin_send = this.sendCopiesToAdmins ? 1 : 0
            this.text.delivery_at = `${this.date} ${this.time}`
            this.text.is_kps_send = this.isKpsSend ? 1 : 0
            this.text.kp2_members = this.formatKpsCopy

            if (this.recipientType === 'KPs') {
              this.text.from_dashboard_page = true
              this.text.member_legislator = this.members.map(member => {
                return {
                  member_id: member.id,
                  legislator_id: member.legislator.id,
                }
              })
            }
            if (this.recipientType === 'Coordinators') {
              this.text.to_regional_coordinators = true
            }
            if (this.recipientType === 'Advocates') {
              this.text.from_members_page = true
            }
            if(this.recipientType !== 'KPs') {
              this.text.members = this.members.filter(member => member.email).map(member => member.id)
            }

            this.onSendText()
          }
        }
      })
    },
    async addFiles() {
      if (this.email.files && this.email.files.length) {
        this.isUploadingFiles = true
        let promises = []

        for (let file of this.email.files) {
          let data = new FormData()
          data.append('file', file)
          promises.push(this.saveScheduleMessageFile(data))
        }

        this.email.files = (await Promise.all(promises)).map(res => {
          return res.data
        })
      }
    },
    somethingWentWrong() {
      this.isBusy = false
      this.isUploadingFiles = false
      this.error = 'Something went wrong!'
    },
    childError() {
      this.error = ''
    },
    onBadVariables(value) {
      this.isBadVariables = value
    },
    closeModal() {
      this.$emit('close')
    }
  },
  watch: {
    isEmail: function () {
      this.isBadVariables = false
    },
    recipientType: function () {
      this.isBadVariables = false
      this.messageTemplates = _.cloneDeep(this.allMessageTemplates).filter((message) => {
        if (this.recipientType === 'KPs' && this.unique === true) {
          return message.on_kp_unique
        } else if (this.recipientType === 'KPs' && this.unique === false) {
          return message.on_kp
        } else if (this.recipientType === 'Coordinators') {
          return message.on_coordinator
        } else if (this.recipientType === 'Advocates') {
          return message.on_advocate
        }
        return false
      })
      if (this.recipientType === 'KPs') {
        this.message = `Hi {kp_first},<p><br/></p>Thanks! -${this.userData.nickname}`
      } else {
        this.message = `Hi {advocate_first},<p><br/></p>Thanks! -${this.userData.nickname}`
      }
    },
    unique: function () {
      this.isBadVariables = false
      this.messageTemplates = _.cloneDeep(this.allMessageTemplates).filter((message) => {
        if (this.recipientType === 'KPs' && this.unique) {
          return message.on_kp_unique
        } else if (this.recipientType === 'KPs' && !this.unique) {
          return message.on_kp
        }
      })
    }
  }
}
</script>

